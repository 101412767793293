import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import { renderOptions, documentToReactComponents, renderRichText } from "../components/renderRichText"
import ConvertKitForm from "convertkit-react"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import {
  Container,
  Box,
  Space,
  Heading,
  HomepageImage,
  Text,
} from "../components/ui"
import * as styles from "./blog-post.css"
import { colors } from "../colors.css"

export interface BlogPost {
  id: string
  slug: string
  title: string
  excerpt: string
  snippet: string
  category: string
  date: string
  html: string
  image: HomepageImage
  next?: BlogPost
  previous?: BlogPost
}

interface QueryReturn {
  post: BlogPost
}

const Post: React.FC<PageProps<QueryReturn>> = ({ data: { post } }) => {
  const convertkitConfig = {
    formId: 3453686,
    template: "mills",
    headingText: "Free UX portfolio project guide",
    disclaimerText: "You won't get sent any spam, and you can unsubscribe at any time. Promise.",
    buttonBackground: colors.purple,
    buttonColor: colors.black,
    // emailPlaceholder: 'Enter an email address',
    // submitText: 'Sign up',
  }
  return (
    <Layout {...post} description={post.excerpt}>
      <Container  width="narrow" className={styles.blogPost}>
        <Box paddingY={5}>
          <Heading as="h1">
            {post.title}
          </Heading>
          <Space size={5} />
          {
            documentToReactComponents(
              JSON.parse(post.body.raw)
            )
            &&
            renderRichText(
              post.body, renderOptions()
            )
          }
        </Box>
        {post.snippet && post.snippet == "ConvertKit" &&
          <Box center>
            <ConvertKitForm {...convertkitConfig}>
              <Text variant="small">Subscribe to get the latest updates, as well as my tried and tested list of <strong>UX portfolio documentation</strong>.</Text>
            </ConvertKitForm>
          </Box>
        }
      </Container>
    </Layout>
  )
}

export default Post

export const Head: HeadFC<QueryReturn> = ({ data: { post }, location }) => (
  <SEO title={post.title} description={post.excerpt} pathname={location.pathname} />
)

export const query = graphql`
  query ($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      body {
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            description
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        raw
      }
      excerpt
      snippet
    }
  }
`